import React from 'react';
import htmlParser from 'html-react-parser';

import type { ImageProps } from 'Elements/Image';

import { ListContainer, ListItem, ItemWrapper, Icon, CustomImage } from './LineList.styled';

export interface Props {
    borderColor?: string;
    color?: string;
    items: {
        id: string;
        icon?: React.ElementType | ImageProps;
        iconColour?: string;
        text: string;
    }[];
}

const LineList: React.FC<Props> = ({ borderColor, color, items }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isImage(obj: any): obj is ImageProps {
        return 'src' in obj;
    }

    return (
        <ListContainer
            style={
                {
                    '--linelist-color': color,
                    '--linelist-border-color': borderColor
                } as React.CSSProperties
            }
        >
            {items.map(listItem => (
                <ListItem key={listItem.id}>
                    {listItem.icon &&
                        (!isImage(listItem.icon) ? (
                            <Icon
                                as={listItem.icon}
                                style={
                                    {
                                        '--linelist-icon-colour': listItem.iconColour
                                    } as React.CSSProperties
                                }
                            />
                        ) : (
                            <CustomImage src={listItem.icon.src} alt={listItem.icon.alt || ''} />
                        ))}
                    <ItemWrapper>{htmlParser(listItem.text)}</ItemWrapper>
                </ListItem>
            ))}
        </ListContainer>
    );
};

export default LineList;
